import React from "react"  

const Nav = () => (  
    <header id="header">
      <h1><a href="/">nerdleader</a></h1>
{/*        <nav className="links">
          <ul>
            <StaticQuery
              query={graphql`
                query {
                  allStrapiCategories {
                    edges {
                      node {
                        strapiId
                        name
                      }
                    }
                  }
                }
              `}
              render={data =>
                data.allStrapiCategories.edges.map((category, i) => {
                  return (
                    <li key={category.node.strapiId}>
                      <Link to={`/category/${category.node.strapiId}`}>
                        {category.node.name}
                      </Link>
                    </li>
                  )
                })
             }
            />
          </ul>
      </nav> */}
    </header>
)

export default Nav  
