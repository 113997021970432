import React from "react"  
import PropTypes from "prop-types"

import Nav from "./nav"
import Seo from "./seo"

import "../assets/css/main.css"

const Layout = ({ children }) => {  
  return (
<>
      <Seo />
      <Nav />
      {children}
</>
  )
}

Layout.propTypes = {  
  children: PropTypes.node.isRequired,
}

export default Layout
